export const wytgColumn = [
  {
      prop: "applyId",
      label: "申请单号",
      width: 100,
  },
  {
      prop: "title",
      label: "产品名称",
  },

  {
      prop: "departName",
      label: "所属机构",
  },
  {
      prop: "approveMoney:",
      label: "批准额度",
  },
  {
      prop: "applyTime",
      label: "提交时间",
      width: 80,
  },
  {
      prop: "status",
      label: "审批状态",
      width: 140,
      template:true
  },
  {
      prop: "name",
      label: "申请人",
      width: 140,
  },
  {
      prop: "area",
      label: "地址",
  },
  {
    prop: "approveTime",
    label: "批准时间",
    width: 140,
},
  {
      prop: "operation",
      label: "操作",
      width: 140,
      template: true,
  },
]
